:root {
    --gp: 16px;
}

@import 'reset.css';

/* Global scaling */
html {
    font-size: var(--gp);
    font-family: 'Nunito', sans-serif;
}

@media (max-width: 900px) {
    html { font-size: var(--gp) - 2; }
}

@media (max-width: 400px) {
    html { font-size: var(--gp) - 4; }
}

/* Typeography */
h1 {
    font-size: 3em;
}

h2 {
    font-size: 2.5em;
}

h3 {
    font-size: 2em;
}

p {
    font-size: 1em;
    line-height: normal;
    margin-bottom: 1rem;
}

a {
    border-bottom: 1px solid;
    cursor: pointer;
    color: black;
}

a:visited {
    border-bottom: 1px solid;
    cursor: pointer;
    color: black;
}

a:any-link {
    border-bottom: 1px solid;
    cursor: pointer;
    text-decoration: none;
}